import 'date-fns';
import { Link } from 'react-router-dom';
import {useEffect, useCallback, useState, useRef} from 'react';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function EditRoute() {
  const [isLoading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [route, setRoute] = useState();
  const [action, setAction] = useState('update');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

 
  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
      const response = await api.get(`/route/${id}`);
      setRoute(response.data);
 
      setLoaded(true);
      setLoading(false);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/route");
    }
  },[]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Detalhes Rota - {route?.name}</h4>
            </CardHeader>
            <CardBody>

          
              <p><strong>Nome Rota: {route?.name} </strong></p>
            
              <Link to="/admin/route"> <Button color="info">VOLTAR</Button></Link>
 

            </CardBody>
          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
