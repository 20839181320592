
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Button from 'components/CustomButtons/Button.js';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import View from '@material-ui/icons/Visibility';

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableColumn, tableData, resource, tableHeaderColor, tableColumnSize, onEdit, onDelete, onAddPhoto, onDownloadXml } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{ width: tableColumnSize[key] }}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((data, key) => {

            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {tableColumn.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {data[prop] && prop !== 'image' ? data[prop] : null}
                      {prop === 'image' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}group/${data["image"]}`} width={60}></img> : null}
                      {prop === 'edit' ? <Link to={`/admin/${resource}/${data["id"]}`}><Button color="info" round><Edit /></Button></Link> : null}
                      {prop === 'view' ? <Link to={`/admin/${resource}/${data["id"]}`}><Button color="success" round><View /></Button></Link> : null}
                      {prop === 'delete' && data["id"] !== 'a8c55ccd-fc57-4b9f-a2f7-9cb05ca6f865' ? <Button color="danger" round onClick={() => onDelete(data["id"])}><Delete /></Button> : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
};
